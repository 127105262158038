import React from 'react';
import Header from 'components/Header';
import './style.scss';
import {
	Link
} from 'react-router-dom';
import {
	apiPost
} from 'api';
import BackButton from "../Elements/BackButton";

async function send(memberships, setRefresh, route) {
	await apiPost( 'add-road-help', {
		name_id: memberships.own.name_id
	} );
	setRefresh(Date.now());
	route.history.goBack();
}

function RoadHelp( props ) {
	const {
		route,
		setRefresh,
		memberships,
	} = props;
	return (
		<div>
			<Header navigation="menu">
				<div className="logo" />
			</Header>

			<BackButton/>

			<div className="view login">
			<div />
				<div className="box">
				<p className="large">Ønsker du personlig bergingsavtale uten egenandel som gjelder i hele Europa?</p>
				<p><a target="_blank" rel="noopener noreferrer" href="https://kna.no/medlemsfordeler/kna-veihjelp/">Les mer om veihjelp</a></p>
				<p className="very-large"><strong>kr 470 / år</strong></p>
				</div>

			<div className={"view-controllers"}>
				<Link to="/"><button>Avbryt</button></Link>
				<button type="button" className="important" onClick={() => {send(memberships, setRefresh, route)}}>Legg til</button>
			</div>
			</div>
		</div>
	);
}
export default RoadHelp;
