import React from 'react';

import './style.scss';
import {Link} from "react-router-dom";

function DashCard ( props ) {
	const {
		linkTo,
		icon
	} = props;

	return (
		<Link to={linkTo} className={ 'undefined' !== props.disabled && props.disabled ? 'dashcard disabled' : 'dashcard' }>
			{ icon &&
				<span className={ "icon " + icon }/>
			}
			<h2>
				{ props.children }
			</h2>
		</Link>
	);
}

export default DashCard;