import React, {
	useEffect,
	useState
} from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Header from 'components/Header';
import './style.scss';
import {
	Link
} from 'react-router-dom';
import {
	apiPost,
	apiRelay,
} from 'api';
import BackButton from "../Elements/BackButton";
import GeneralToS from "../TermsOfService/General";
import RoadsideToS from "../TermsOfService/Roadside";
import {Checkbox, FormLabel, Grid, Radio, RadioGroup} from "@material-ui/core";

const apiUrl = process.env.REACT_APP_API_URL;

function Register( props ) {
	const {
		route,
		userId,
		user,
		setRefresh
	} = props;

	const handleChange = event => {
		const name = event.target.name;
		const value = event.target.value;
		setValues( oldValues => ( {
			...oldValues,
      		[ name ]: value,
		} ) );
	};

	const handleRadioChange = event => {
		setValues( oldValues => ( {
			...oldValues,
			[ event.target.name ]: parseInt( event.target.value ),
		} ) );
	};

	const handleCheckChange = ({ target: { name, checked } }) => {
		setValues( oldValues => ( {
			...oldValues,
			[ name ]: checked,
		} ) );
	};

	// Holds Terms of Services from our API endpoints.
	const [ terms, setTerms ] = useState({
		consent: null,
		consentRoad: null
	} );

	// Confirm if the pre-requisites to signing up are met.
	const canCompleteSignup = () => {
		// Check if the Terms of Service have been read and accepted.
		if ( ! values.acceptToS ) {
			return false;
		}

		// IF roadside assistance is chosen, a second terms of service is also required.
		if ( 1 === values.type && ! values.acceptRoadsideToS ) {
			return false;
		}

		return true;
	};

	const [ values, setValues ] = useState( {
		type: 1,
		address: user.address,
		postal_code: user.postal_code,
		acceptToS: false,
		acceptRoadsideToS: false
	} );
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modal, setModal] = useState( 0 );

	const [ oldPostal, setOldPostal ] = useState( '' );
	useEffect( () => {
		if ( values.postal_code && 4 === values.postal_code.length && values.postal_code !== oldPostal ) {
			apiRelay(
				'member/get-kommune-by-postal-code',
				{
					country_code: 'NO',
					postal_code: values.postal_code}
			).then( response => {
				if(response.status === 'ok') {
					console.dir(response);
					setValues( oldValues => ({
						...oldValues,
						postal_city: response.data.kommune.title
					}) );
				}
			} );
			setOldPostal(values.postal_code);
		}
	}, [ values, oldPostal ] );

	useEffect( () => {
		if ( 0 === modal ) {
			document.getElementById( 'root' ).querySelector( '.app' ).classList.remove( 'modal-visible' );
		} else {
			document.getElementById( 'root' ).querySelector( '.app' ).classList.add( 'modal-visible' );
		}
	}, [ modal ] );

	useEffect( () => {
		fetch(
			`${apiUrl}kna/v1/terms-of-service`,
			{
				method: 'GET'
			}
		).then( response => response.json() ).then( ( response ) => {
			setTerms( response );
		} );
	}, [] );

	if ( 1 === modal ) {
		return (
			<GeneralToS
				setValues={setValues}
				setModal={setModal}
				terms={terms}
			/>
		)
	}

	if ( 2 === modal ) {
		return (
			<RoadsideToS
				setValues={setValues}
				setModal={setModal}
				terms={terms}
			/>
		)
	}

	return (
		<div>
			<Header navigation="menu">
				<div className="logo" />
			</Header>

			<BackButton linkTo="/medlemskap" label="Medlemskap" />

			<div className="view login">
				<ValidatorForm
						onSubmit={async() => {
							let memberData = values;
							switch (memberData.type) {
								case 2:
									memberData.membership = "ME-0-9";
									memberData.veihjelp = false;
									break;
								case 3:
									memberData.membership = "ME-0-3";
									memberData.veihjelp = false;
									break;
								default:
									memberData.membership = "ME-0-9";
									memberData.veihjelp = true;

									// The Date of Birth is only added when roadside assistance is selected.
									memberData.ssn = values.birthYear + '-' + values.birthMonth + '-' + values.birthDay;
							}
							setLoading(true);
							const response = await apiPost( 'create-family-member', {
								main: {
									name_id: userId
								},
								family_members: [
									memberData
								]
							} );
							setLoading(false);
							if(response && response.status === 'error') {
								switch (response.message) {
									case "Error getting PaymentID":
										setError('Problem med betaling, prøv igen senare eller kontakt support.');
										break;
									default:
										setError('Kunne ikke opprette konto, kanskje mobilnummeret allerede er registrert?');
								}
							} else {
								setRefresh(Date.now());
								route.history.goBack();
							}
						}}
						onError={errors => console.log(errors)}
					>
				<div>
					<h1>Nytt familie-medlemsskap</h1>

					<p>
						Gi et rimelig KNA-medlemsskap til famile-medlemmer med samme adresse som deg.
					</p>

					<h2>Velg medlemskap</h2>

					<FormControl component="fieldset">
						<FormLabel component="legend" className="screen-reader-text">Medlemskap</FormLabel>
						<RadioGroup name="type" value={values.type} onChange={handleRadioChange}>
						  <FormControlLabel value={1} control={<Radio />} label="Medlemskap med veihjelp, 495kr/år" />
						  <FormControlLabel value={2} control={<Radio />} label="Medlemskap uten veihjelp, 200kr/år" />
						</RadioGroup>
					</FormControl>
				</div>


				<div>
					<h2>Om familiemedlemmet</h2>

					<Grid container spacing={3}>
						<Grid container item xs={6}>
							<FormLabel component="legend">Fornavn</FormLabel>
							<TextValidator
								variant="outlined"
								inputProps={{
									name: 'first_name',
									id: 'first-name',
								}}
								value={values.first_name}
								onChange={handleChange}
								validators={['required']}
								errorMessages={['Må fylles ut']}
							/>
						</Grid>

						<Grid container item xs={6}>
							<FormLabel component="legend">Etternavn</FormLabel>
							<TextValidator
								variant="outlined"
								inputProps={{
									name: 'last_name',
									id: 'last-name',
								}}
								value={values.last_name}
								onChange={handleChange}
								validators={['required']}
								errorMessages={['Må fylles ut']}
							/>
						</Grid>

						<Grid container item xs={12}>

							<Grid item xs={12}>
								<FormLabel component="legend">Mobilnummer</FormLabel>
							</Grid>

							<Grid item xs={12}>
								<p className="description">
									Her må du legge inn et unikt mobilnummer. Du kan ikke bruke ditt eget mobilnummer.
								</p>
							</Grid>

							<Grid item xs={12}>
								<TextValidator
									inputProps={{
										name: 'mobile',
										id: 'mobile',
									}}
									type="tel"
									variant="outlined"
									value={values.mobile}
									onChange={handleChange}
									validators={['required', 'isNumber']}
									errorMessages={['Må fylles ut', 'Ugyldig mobilnummer']}
								/>
							</Grid>
						</Grid>

						{1 === values.type &&
						<Grid container item xs={12}>
							<FormLabel component="legend">Fødselsdato</FormLabel>

							<p className="description">
								Vi trenger fødselsdatoen for å kunne tilby veihjelp
							</p>

							<Grid container spacing={3}>
								<Grid container item xs={4}>
									<Grid item xs={12}>
										<FormLabel component="legend">Dag</FormLabel>
									</Grid>

									<Grid item xs={12}>
										<TextValidator
											inputProps={{
												name: 'birthDay',
												id: 'birthDay',
												min: 1,
												max: 31
											}}
											placeholder="DD"
											type="number"
											variant="outlined"
											value={values.birthDay}
											onChange={handleChange}
											validators={['required', 'isNumber']}
											errorMessages={['Må fylles ut', 'Ugyldig fødselsdato']}
										/>
									</Grid>
								</Grid>

								<Grid container item xs={4}>
									<Grid item xs={12}>
										<FormLabel component="legend">Måned</FormLabel>
									</Grid>

									<Grid item xs={12}>
										<TextValidator
											inputProps={{
												name: 'birthMonth',
												id: 'birthMonth',
												min: 1,
												max: 12
											}}
											placeholder="MM"
											type="number"
											variant="outlined"
											value={values.birthMonth}
											onChange={handleChange}
											validators={['required', 'isNumber']}
											errorMessages={['Må fylles ut', 'Ugyldig fødselsmåned']}
										/>
									</Grid>
								</Grid>

								<Grid container item xs={4}>
									<Grid item xs={12}>
										<FormLabel component="legend">År</FormLabel>
									</Grid>

									<Grid item xs={12}>
										<TextValidator
											inputProps={{
												name: 'birthYear',
												id: 'birthYear',
												min: 1900,
												max: new Date().getFullYear()
											}}
											placeholder="ÅÅÅÅ"
											type="number"
											variant="outlined"
											value={values.birthYear}
											onChange={handleChange}
											validators={['required', 'isNumber']}
											errorMessages={['Må fylles ut', 'Ugyldig fødselsår']}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						}

						<Grid container item xs={12}>
							<Grid item xs={12}>
								<FormLabel component="legend">E-postadresse</FormLabel>
							</Grid>

							<Grid item xs={12}>
								<TextValidator
									placeholder="navn@epost.no"
									type="email"
									variant="outlined"
									autoComplete="email"
									value={values.email}
									onChange={handleChange}
									inputProps={{
										name: 'email',
										id: 'email',
									}}
									validators={['required', 'isEmail']}
									errorMessages={['Må fylles ut', 'Ugyldig epost']}
								/>
							</Grid>
						</Grid>

						<Grid container item xs={12}>
							<Grid item xs={12}>
								<FormLabel component="legend">Gateadresse</FormLabel>
							</Grid>

							<Grid item xs={12}>
								<TextValidator
									placeholder="Gateadresse"
									variant="outlined"
									value={values.address}
									onChange={handleChange}
									inputProps={{
										name: 'address',
										id: 'address',
									}}
									validators={['required']}
									errorMessages={['Må fylles ut']}
								/>
							</Grid>
						</Grid>

						<Grid container item xs={12}>
							<Grid container spacing={3}>
								<Grid container item xs={3}>
									<Grid item xs={12}>
										<FormLabel component="legend">Postnr.</FormLabel>
									</Grid>

									<Grid item xs={12}>
										<TextValidator
											placeholder="0100"
											variant="outlined"
											value={values.postal_code}
											onChange={handleChange}
											inputProps={{
												name: 'postal_code',
												id: 'postal_code',
											}}
											type="number"
											validators={['required', 'isNumber', 'minNumber:100']}
											errorMessages={['Må fylles ut', 'invalid zip', 'invalid zip']}
										/>
									</Grid>
								</Grid>

								<Grid container item xs={9}>
									<Grid item xs={12}>
										<FormLabel component="legend">Poststed</FormLabel>
									</Grid>

									<Grid item xs={12}>
										<TextValidator
											placeholder="Poststed"
											variant="outlined"
											value={values.postal_city}
											inputProps={{
												name: 'postal_city',
												id: 'postal_city',
												readOnly: true,
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container item xs={12}>
							<FormControlLabel
								label={
									<>
										Jeg har lest og godtatt <span className="link-style" onClick={ ( e ) => { setModal( 1 ); } }>personvernerklæringen og medlemsvilkårene</span>
									</>
								}
								control={<Checkbox
									color="primary"
									checked={values.acceptToS}
									onChange={handleCheckChange}
									inputProps={{
										name: 'acceptToS',
										id: 'acceptToS',
									}}
									required={true}
								/>}
							/>

							{ 1 === values.type &&
								<FormControlLabel
									label={
										<>
											Jeg godtar <span className="link-style" onClick={ ( e ) => { setModal( 2 ); } }>vilkårene for veihjelp</span>
										</>
									}
									control={<Checkbox
										color="primary"
										checked={values.acceptRoadsideToS}
										onChange={handleCheckChange}
										inputProps={{
											name: 'acceptRoadsideToS',
											id: 'acceptRoadsideToS',
										}}
										required={true}
									/>}
								/>
							}
						</Grid>
					</Grid>
				</div>

				{(error && !loading) &&
					<div className="error">{error}</div>
				}

				<div className="form-actions">
					<button type="submit" className={"button inline primary" + ( loading || ! canCompleteSignup() ? ' disabled' : '' )}>Bekreft innmelding</button>

					<Link className="button secondary inline" to="/">Avbryt</Link>
				</div>
				</ValidatorForm>
			</div>
		</div>
	);
}
export default Register;
