import React from 'react';

import '../style.scss';

function RoadsideToS( props ) {
    const {
        setValues,
        setModal,
        terms
    } = props;

    const confirmAndClose = () => {
        setValues( oldValues => ( {
            ...oldValues,
            acceptRoadsideToS: true,
        } ) );

        setModal( 0 );
    };
    
    return (
        <>
            <button type="button" className="close-modal-button" onClick={ () => { setModal( 0 ); } }>X</button>

            <h2>Vilkår for veihjelp</h2>

            <div dangerouslySetInnerHTML={{__html:terms.consentRoad}} />

            <button className="primary green" onClick={confirmAndClose}>Jeg godtar vilkårene</button>
        </>
    )
}

export default RoadsideToS;