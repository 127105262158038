import React, {useEffect, useState} from 'react';
import Header from './Header';
import DashCardGrid from "./Elements/DashCardGrid";
import DashCard from "./Elements/DashCardGrid/DashCard";
import ExpiredPayment from "./ExpiredPayment";
import SupportCard from "./Elements/SupportCard";
import Footer from "./Footer";
import Notice from "./Elements/Notice";
import {Link} from 'react-router-dom';

function Dash( props ) {
	const {
		userId,
		member,
		mainMembership,
		roadHelpPaid,
		hasPaidMembership,
		hasPendingPayment,
		customerID
	} = props;

	const [ paymentType, setPaymentType ] = useState( 'invoice' );
	const expiryReminderDays = 7; // How many days before a payment option expires should the warning be displayed?

	/**
	 * ID numbers used to identify payment methods, as defined by Profundo:
	 *
	 * 0: giro/faktura
	 * 1: Avtalegiro, pre-agreement (rare occurrence)
	 * 5: Avtalegiro, active agreement
	 * 13: Vipps
	 * 14: Stripe (card)
	 */
	useEffect( () => {
		// Invoice payments are considered a fallback when nothing can be interpreted.
		if ( false === mainMembership || typeof mainMembership === "undefined" || typeof mainMembership.payment_method === "undefined" || typeof mainMembership.payment_method.id === "undefined" ) {
			setPaymentType( 'invoice' );
		} else {
			switch ( mainMembership.payment_method.id ) {
				case 14:
					setPaymentType( 'card' );
					break;
				case 13:
					setPaymentType( 'vipps' );
					break;
				case 5:
				case 1:
				case 0:
				default:
					setPaymentType( 'invoice' );
			}
		}
	}, [ mainMembership ] );

	if ( !userId ) {
		return null;
	}

	// Days until payment method expires. -1 if already expired.
	const daysUntilExpiration = ( expiryDate ) => {
		// The date we get from Profundo is not in an ISO format, so we split it and pass it back as ISO.
		expiryDate = expiryDate.split( '.' );
		const expiryDateIso = new Date( expiryDate[2] + '-' + expiryDate[1] + '-' + expiryDate[0] );

		const timeToExpiration = expiryDateIso.getTime() - Date.now(); // Time to expiration in miliseconds.

		// Return -1 if expired already.
		if ( timeToExpiration < 0 ) {
			return -1;
		}

		// Return number of whole days left until expiry.
		return Math.floor(timeToExpiration / (24*60*60*1000));
	}

	return (
		<div>
			<Header navigation="menu">
				<div className="logo" />
			</Header>

			<div className="view">
				{ 'card' === paymentType && typeof mainMembership.payment_method !== "undefined" && null !== mainMembership.payment_method.vendor_data && daysUntilExpiration( mainMembership.payment_method.vendor_data.card_expires ) < expiryReminderDays &&
					<ExpiredPayment
						cardIsExpired={ -1 === daysUntilExpiration( mainMembership.payment_method.vendor_data.card_expires ) }
						membershipIsPaused={ mainMembership.paused }
					/>
				}

				{ member && ! hasPaidMembership && 'card' !== paymentType && ! hasPendingPayment && (
					// Display "missing payment" notice.
					<Notice
						heading="Manglende betaling"
					>
						<p>
							Ditt KNA-medlemskap har ikke blitt betalt{mainMembership.paused && (' og er inaktivt')}.
						</p>
						<p>
							<Link to="/medlemskap" className={"button inline primary " + (member ? '' : ' disabled')}>
								Administrer medlemskap
							</Link>
						</p>
					</Notice>
				) }

				{ hasPendingPayment &&
				// Display  "payment pending" notice.
				<Notice
					heading="Betaling under behandling"
				>
					<p>Du har en betaling under behandling, og kan derfor ikke endre betalingsmåte eller gjennomføre nye ordre ennå.</p>
					<p>Dette kan i svært få tilfeller ta opp mot en time.</p>
				</Notice>
				}

				{ ( ! member || ( ! hasPaidMembership && 'card' !== paymentType ) || hasPendingPayment ) &&
				/**
				 * Display a limited dasboard for users that:
				 * a) is not a member, i.e. has no main membership
				 * b) has a main membership that isn't paid, and is not a card client, but uses invoice (legacy paper oblat or invoice) or Vipps.
				 * c) has one or more pending payments
				 */ 
					<>
						<DashCardGrid>
							<DashCard
								icon="person"
								linkTo="/innstillinger"
							>
								Min profil
							</DashCard>

							<DashCard
								icon="car"
								linkTo="/medlemskap"
							>
								Medlemskap
							</DashCard>
						</DashCardGrid>
					</>
				}

				{( member && hasPaidMembership) &&
					<>
						<DashCardGrid>
							<DashCard
								icon="card large"
								linkTo="/medlemskort"
							>
								Medlemskort
							</DashCard>

							<DashCard
								icon="sos large"
								linkTo="/berging"
								disabled={!roadHelpPaid}
							>
								Bilberging
							</DashCard>

							<DashCard
								icon="car large"
								linkTo="/medlemskap"
							>
								Medlemskap
							</DashCard>

							<DashCard
								icon="person large"
								linkTo="/innstillinger"
							>
								Min profil
							</DashCard>
							{
								// Only show "change card" for card users with a Stripe customer id.
								'card' === paymentType && customerID &&
								<DashCard
									icon="creditcard large"
									linkTo="/betaling/kort"
								>
									Oppdater
									<br/>
									kortdetaljer
								</DashCard>
							}
							
						</DashCardGrid>

						<SupportCard/>
					</>
				}
			</div>

			<Footer />

		</div>
	);
}
export default Dash;
