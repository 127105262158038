import React from 'react';
import {Link} from "react-router-dom";

import './style.scss';

function BackButton( props ) {
	let {
		linkTo,
		label
	} = props;

	linkTo = linkTo || '/';
	label = label || 'Min side';

	return (
		<Link to={ linkTo } href="#" className="back-button">
			<span className="icon arrow-left"/>
			{ label }
		</Link>
	);
}

export default BackButton;