import React from 'react';

import './style.scss';

function Notice( props ) {

    const {
        heading
    } = props;

    return (
        <>
            <div className="generic-notice">
                <div className="notice-header">
                    <span className="title">
                        {heading}
                    </span>
                </div>

                {props.children}
            </div>
        </>
    )

}

export default Notice;