import React, {
	useState,
	useRef
} from 'react';
import {
	apiPost
} from 'api';
import Header from 'components/Header';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import './style.scss';

import logo1 from '../../assets/nicolaisen.png';
import logo2 from '../../assets/tenden.png';
import logo3 from '../../assets/kb.png';
import BackButton from "../Elements/BackButton";

async function send(values, memberships, setDone) {
	const response = await apiPost( 'contact-email', {
		"name_id": memberships.own.name_id,
		"subject": "Online Rådgiving - " + values.type,
		"message": values.question,
		"topic": values.type,
	} );
	if(response.status === 'ok') {
		setDone(true);
	}
}

function Support( props ) {
	const handleChange = event => {
		const name = event.target.name;
		const value = event.target.value;
		setValues( oldValues => ( {
			...oldValues,
			[ name ]: value,
		} ) );
	};
	const [ values, setValues ] = useState( {
		question: '',
	} );
	const inputLabel = useRef( null );
	const [ labelWidth, setLabelWidth ] = useState( 0 );
	const [ done, setDone ] = useState( false );
	React.useEffect( () => {
		setLabelWidth( inputLabel.current.offsetWidth );
	}, [] );

	return (
		<div>
			<Header navigation="menu">
				<div className="logo" />
			</Header>

			<BackButton/>

			<div className="view">
				<h1>Rådgiving</h1>

				<p>Vi hjelper deg som medlem med fagkompetanse i alle spørsmål knyttet til å eie og bruke bil</p>
				<p>Bruk dette kontaktskjemaet for å komme kontakt med KNAs biltekniske eksperter og advokater.</p>
					{!done &&
						<>
						<ul className="input-list">
							<li>
								<FormControl variant="outlined">
									<InputLabel ref={inputLabel} htmlFor="outlined-age-simple">Hva trenger du hjelp med?</InputLabel>
									<Select
											value={values.type}
											onChange={handleChange}
											labelWidth={labelWidth}
											inputProps={{
												name: 'type',
												id: 'type',
											}}>
									  <MenuItem value={"Reklamasjon etter kjøp av bil"}>Reklamasjon etter kjøp av bil</MenuItem>
									  <MenuItem value={"Reklamasjon på verkstedtjenester/deler"}>Reklamasjon på verkstedtjenester/deler</MenuItem>
									  <MenuItem value={"Førerkortbeslag"}>Førerkortbeslag</MenuItem>
												<MenuItem value={"Klage på parkeringsavgift"}>Klage på parkeringsavgift</MenuItem>
												<MenuItem value={"Reklamasjon etter salg av bil"}>Reklamasjon etter salg av bil</MenuItem>
												<MenuItem value={"Biltekniske spørsmål"}>Biltekniske spørsmål</MenuItem>
												<MenuItem value={"Annet"}>Annet</MenuItem>
									</Select>
								</FormControl>
							</li>
							<li>
								<TextField
									label="Ditt spørsmål"
									variant="outlined"
									inputProps={{
										name: 'question',
										id: 'question',
									}}
									value={values.question}
									rows="5"
									onChange={handleChange}
									multiline
								/>
							</li>
						</ul>
					<button className="button inline primary" onClick={() => {send(values, props.memberships, setDone)}}>Send henvendelse</button>
						</>
					}
					{done &&
						<p className="notice success"><strong>Takk for din henvendelse!</strong></p>
					}
				<div className="partners">
					<div className="logos">
						<img alt="" src={logo1} />
						<img alt="" src={logo2} />
						<img alt="" src={logo3} />
					</div>
				</div>
			</div>
		</div>
	);
}
export default Support;
