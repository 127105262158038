import React from 'react';

import { Link } from "react-router-dom";

import './style.scss';

function SupportCard() {
	return (
		<div className="support-card">
			<div className="icon-wrapper">
				<span className="icon support x-large"/>
			</div>

			<div>
				<h2>Trenger du hjelp</h2>

				<p>
					Vi hjelper deg som medlem med fag-kompetanse i alle spørsmål knyttet til å eie eller bruke bil.
				</p>

				<p>
					<Link to="/radgiving">Få rådgivning</Link>
				</p>
			</div>
		</div>
	)
}

export default SupportCard;