import React from 'react';

function Formatted( props ) {
	const {
		phoneNumber
	} = props;

	const formattedNumber = phoneNumber.replace( /(\d{3})(\d{2})(\d{3})/, '$1 $2 $3' );

	return (
		<span className="phonenumber">{ formattedNumber }</span>
	);
}

export default Formatted;