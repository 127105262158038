import React from 'react';
import { Link } from "react-router-dom";

function MenuItems() {
    return (
        <ul>
            <li><Link to="/">Min side</Link></li>
            <li><Link to="/innstillinger">Min profil</Link></li>
            <li><Link to="/medlemskap">Mitt medlemskap</Link></li>
            <li><Link to="/medlemskort">Se medlemskort</Link></li>
            <li><Link to="/betaling">Endre betalingsmåte</Link></li>
            <li><Link to="/radgiving">Juridisk rådgivning</Link></li>
            <li><Link to="/berging" className="important">Tilkall bilberging</Link></li>
            <li><Link to="/logout" className="important">Logg ut</Link></li>
        </ul>
    )
}

export default MenuItems;
