import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
	CardElement,
	Elements,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import Header from 'components/Header';
import {
	apiPost,
} from 'api';
import BackButton from '../Elements/BackButton';
import Notice from '../Elements/Notice';

if ( ! process.env.REACT_APP_STRIPE_KEY ) {
	console.error( 'No Stripe Public Key Set.' );
}
const stripePromise = loadStripe( process.env.REACT_APP_STRIPE_KEY );

const CheckoutChangeCard = ({ setLoading, setLastDigits, setDone, customerID }) => {
	const [cardError, setCardError] = useState( false );
	const [performingChange, setPerformingChange] = useState( false );

	const stripe = useStripe();
	const elements = useElements();

	let changeButtonLabel = 'Fullfør endring';

	const handleSubmit = async ( event ) => {
		event.preventDefault();
		setPerformingChange( true );
		const { token, error } = await stripe.createToken( elements.getElement( CardElement ) );
		if ( error ) {
			setPerformingChange( false );
			if(error.type === 'validation_error') {
				setCardError(error.message);
			}
		} else {
			const updateData = { 'token' : token, 'customer_id': customerID }
			const response = await apiPost( 'stripe-update-payment-method', updateData );
			if ( response && response.id ) {
				setPerformingChange( false );
				elements.getElement( CardElement ).clear();
				setLoading(false);
				setLastDigits( response.last4 );
				setDone(true);
			} else {
				setPerformingChange( false );
				setLoading( false );
				let responseMessage = response;
				if ( response.status ) {
					responseMessage = response.status
				}
				setCardError( 'error ' + responseMessage + ' - Could not handle request' );
			}
		}
	}

	if ( performingChange ) {
		changeButtonLabel = 'Endring behandles...';
	} else {
		changeButtonLabel = 'Oppdater kortdetaljer';
	}

	return (
		<>
			<div className={'block payment' + (cardError ? ' error' : '')}>
				<div className="payment-header" />
				<CardElement onChange={()=>setCardError(false)} />
				{cardError &&
					<span className="error">{cardError}</span>
				}
			</div>
			<div className='send'>
				<button onClick={handleSubmit} className='wp-block-button__link' disabled={performingChange} >
					{changeButtonLabel}
				</button>
			</div>
		</>
	);
};

function ChangeCard(props) {
	const {
		member,
		memberships,
		customerID
	} = props;
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const [lastDigits, setLastDigits] = useState( false );
	if(!memberships.own) {
		return null;
	}

	return (
		<div>
			<Header navigation="menu">
				<div className="logo" />
			</Header>

			{ member &&
				<BackButton/>
			}

			<div className={ "view" + ( loading ? ' loading' : '' ) + ( done ? ' done' : '' ) }>
				<h1>Oppdater kortdetaljer</h1>
				<p>Her kan du erstatte ditt eksisterende betalingskort dersom ditt eksisterende kort f.eks. er utløpt eller snart fornyes.</p>
				{ done &&
					<Notice type="warning" heading="Ditt betalingskort er oppdatert">
						{ lastDigits &&
							<p>Ditt nye kort, som ender med sifrene {lastDigits}, er nå aktivt for din konto.</p>
						}
					</Notice>
				}
				<div className="payment-wrap">
					<Elements stripe={stripePromise}>
						<CheckoutChangeCard
							setLoading={setLoading}
							setDone={setDone}
							setLastDigits={setLastDigits}
							customerID={customerID}
						/>
					</Elements>
				</div>
			</div>
		</div>
	);
}

export default ChangeCard;
