import React, {
	useState,
	useEffect
} from 'react';
import TextField from '@material-ui/core/TextField';
import Header from 'components/Header';
import {
	apiRelay,
} from 'api';
import BackButton from "../Elements/BackButton";
import FormattedPhoneNumber from "../Elements/PhoneNumber/Formatted";

import './style.scss';
import Notice from "../Elements/Notice";

function update( userId, values, setEdit, setRefresh, setProfileUpdates ) {
	const data = {
		name_id: userId,
		name: values
	}
	async function postUpdate() {
		const response = await apiRelay( 'profile/update-name', data );
		/**
		 * A note on the "ok" status response:
		 * The API will return "ok" as long as the name_id is an existing user,
		 * regardless of other parameters or if the profile was actually updated.
		 */
		if ( response && response.status === 'ok' ) {
			setEdit( false );
			setRefresh( Date.now() );
			setProfileUpdates( true );
		}
	}
	postUpdate();
}

function updatePassword( user, password, setEditPassword, setPassword, setPasswordUpdated, setPasswordError ) {
	const data = {
		username: user.mobile,
		password: password
	}

	async function postUpdatePassword() {
		const response = await apiRelay( 'auth/update-password', data );

		if (response) {
			switch (response.status) {
				case "ok":
					setEditPassword(false);
					setPasswordUpdated(true);
					// Reset password fields after successful change.
					setPassword({
						old: '',
						new: ''
					});
					break;
				case "missing_old_or_new_password":
					setPasswordError("Vennligst oppgi både gammelt og nytt passord.")
					break;
				case "invalid_credentials":
					setPasswordError("Du oppga feil nåværende passord. Vennligst prøv igjen.")
					break;
				default:
					setPasswordError("Beklager, det oppstod en feil. Vennligst prøv igjen og kontakt oss om problemet vedvarer. Feilmelding: \"" + response.status + "\"")
			}
		}
	}

	postUpdatePassword();
}

function Settings( props ) {
	const {
		user,
		userId,
		setRefresh
	} = props;
	const handleChange = event => {
		const name = event.target.name;
		const value = event.target.value;
		setValues( oldValues => ( {
			...oldValues,
     		[ name ]: value,
		} ) );
	};
	const [ values, setValues ] = useState( {} );
	const [ password, setPassword ] = useState( {
		old: '',
		new: ''
	} );

	const [ edit, setEdit ] = useState( false );
	const [ editPassword, setEditPassword ] = useState( false );
	const [ profileUpdated, setProfileUpdates ] = useState( false );
	const [ passwordUpdated, setPasswordUpdated ] = useState( false );
	const [ passwordError, setPasswordError ] = useState( false );

	useEffect( () => {
		setValues( {
			first_name: user.first_name,
			last_name: user.last_name,
			mobile: user.mobile,
			email: user.email,
			address: user.address,
			postal_code: user.postal_code,
			postal_name: user.postal_name,
		} )
	}, [ user ] );

	if ( editPassword ) {
		return (
			<div>
				<Header navigation="menu">
					<div className="logo" />
				</Header>

				<BackButton />

				{ passwordError &&
					<Notice heading="Feil">
						{passwordError}
					</Notice>
				}

				<div className="view">
					<h2>Velg ett nytt passord</h2>
					<ul className="input-list">
						<li>
							<TextField
								label="Gammelt passord"
								variant="outlined"
								type="password"
								inputProps={{
									name: 'old',
									id: 'old-password',
								}}
								value={password.old}
								onChange={e => {
									const value = e.target.value;
									setPassword((oldValues) => ({
										...oldValues,
										old: value,
									}));
								}}
							/>
						</li>
						<li>
							<TextField
							label="Nytt passord"
							variant="outlined"
							type="password"
							inputProps={{
								name: "new",
								id: "new-password",
							}}
							value={password.new}
							onChange={(e) => {
								const value = e.target.value;
								setPassword((oldValues) => ({
									...oldValues,
									new: value,
								}));
							}}
							/>
						</li>
					</ul>

					<button
						onClick={() => updatePassword(user, password, setPassword, setEditPassword, setPasswordUpdated, setPasswordError)}
						className="block-link"
					>Lagre</button>

					<button
						onClick={() => setEditPassword(false)}
						className="block-link unimportant"
					>Avbryt</button>
				</div>
			</div>
		);
	} else {
		return (
			<div>
				<Header navigation="menu">
					<div className="logo" />
				</Header>

				<BackButton/>

				{ profileUpdated &&
					<Notice heading="Profil endret">
						Din profil er oppdatert
					</Notice>
				}

				{passwordUpdated &&
					<Notice heading="Passord endret">
						Passordet ditt er oppdatert
					</Notice>
				}

				<div className="view">
					{(!edit && values && values.mobile) &&
						<>
						<h1>Min profil</h1>
						<ul className="data-list">
							<li>
								<h2 className="label">Navn</h2>
									<span>{values.first_name}</span>
									<span>{values.last_name}</span>
							</li>
							<li>
								<h2 className="label">Mobilnummer</h2>
								<span>
									<FormattedPhoneNumber phoneNumber={values.mobile}/>
								</span>
							</li>
							<li>
								<h2 className="label">E-postadresse</h2>
									<span>{values.email}</span>
							</li>
							<li>
								<h2 className="label">Adresse</h2>
								<span>{values.address}</span>
								<br/>
								<span>{values.postal_code}</span>
								<span>{values.postal_name}</span>
							</li>
						</ul>
						</>
					}
					{(values && values.mobile && edit) &&
						<>
						<h1>Endre profil</h1>
						<ul className="input-list">
							<li>
								<TextField
									label="Fornavn"
									variant="outlined"
									inputProps={{
										name: 'first_name',
										id: 'first-name',
									}}
									value={values.first_name}
									onChange={handleChange}
								/>
								<TextField
									label="Etternavn"
									variant="outlined"
									inputProps={{
										name: 'last_name',
										id: 'last-name',
									}}
									value={values.last_name}
									onChange={handleChange}
								/>
							</li>
							<li className="has-description">
								<TextField
									inputProps={{
										name: 'mobile',
										id: 'mobile',
									}}
									label="Mobil"
									type="tel"
									variant="outlined"
									value={values.mobile}
									onChange={handleChange}
									disabled={true}
								/>

								<p className="description">
									Om du trenger å endre mobilnummer, <a href="mailto: support@profundo.no" >kontakt support</a>.
								</p>
							</li>
							<li>
								<TextField
									label="Epost"
									type="email"
									variant="outlined"
									autoComplete="email"
									value={values.email}
									onChange={handleChange}
									inputProps={{
										name: 'email',
										id: 'email',
									}}
								/>
							</li>
							<li>
								<TextField
									label="Gateadresse"
									variant="outlined"
									value={values.address}
									onChange={handleChange}
									inputProps={{
										name: 'address',
										id: 'address',
									}}
								/>
							</li>

							<li>
								<TextField
									label="Postkode"
									variant="outlined"
									value={values.postal_code}
									onChange={handleChange}
									inputProps={{
										name: 'postal_code',
										id: 'postal_code',
									}}
									type="number"
								/>
								<TextField
									label="By"
									variant="outlined"
									value={values.postal_name}
									onChange={handleChange}
									inputProps={{
										name: 'postal_name',
										id: 'postal_name',
									}}
								/>
							</li>
						</ul>
						</>
					}
					{edit &&
						<div className="form-actions">
							<button
								onClick={() => update(userId, values, setEdit, setRefresh, setProfileUpdates)}
								className="primary inline"
							>
								Lagre endringene
							</button>

							<button
								onClick={() => setEdit(false)}
								className="secondary inline"
							>
								Avbryt
							</button>
						</div>
					}
					{!edit &&
						<div>
							<button
								onClick={() => {
									setEdit(true);
									// Reset any "updated" notice when entering edit mode.
									setProfileUpdates(false);
									setPasswordUpdated(false);
								}}
								className="block-link important"
							>
								Endre kontaktinformasjon
							</button>

							<button
								onClick={() => {
									setEditPassword(true);
									// Reset any "updated" notice when entering edit mode.
									setProfileUpdates(false);
									setPasswordUpdated(false);
									setPasswordError(false);
								}}
								to=""
								className="block-link"
							>
								Bytt passord
							</button>
						</div>
					}
				</div>
			</div>
		);
	}
}
export default Settings;
