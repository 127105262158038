import React, {
	useState,
	useEffect
} from 'react';
import GoogleMapReact from 'google-map-react';
import Header from 'components/Header';
import './style.scss';
import BackButton from '../Elements/BackButton';

function displayError(error) {
	switch (error.code) {
		case 1:
			return(
				<p>
					Din nettleser tillater ikke stedstjenester.
					<br />
					Gå til innstillinger og tillat stedstjenester for Safari, Chrome eller annen nettleser.
				</p>
			);
		default:
		return(
			<p>{error.message}</p>
		);
	}
}

function Locate( props ) {
	const [ cords, setCords ] = useState( {} );
	const [ address, setAddress ] = useState( null );
	const [ error, setError ] = useState( null );

	useEffect( () => {
		navigator.geolocation.getCurrentPosition( position => {
			setCords( {
				lat: position.coords.latitude,
				lng: position.coords.longitude
			} );
		}, setError );
	}, [] );

	if(!props.roadHelpPaid) {
		return null;
	}

	return (
		<div>
			<Header navigation="menu">
				<div className="logo" />
			</Header>

			<BackButton/>

				{ error && (
					<div className="geo-error">
						{ displayError(error) }
						<a className="primary button" href="tel:80031660">Kontakt bergehjelp</a>
						<a className="primary button" href="tel:+4722303050">Kontakt bergehjelp ( utenlandsnummer )</a>
					</div>
				) }

				{(cords.lat && cords.lng) &&
					<div className="tow-wrapper">
						<div className="map">
						<GoogleMapReact
							bootstrapURLKeys={ { key: 'AIzaSyAQiQkBib5KXejiyPb64U18SyNSZ76iyB8' } }
							defaultCenter={ [ parseFloat(cords.lat), parseFloat(cords.lng) ] }
							center={ [ parseFloat(cords.lat), parseFloat(cords.lng) ] }
							defaultZoom={ 13 }
							yesIWantToUseGoogleMapApiInternals
							onGoogleApiLoaded={({ map, maps }) => {
								const geoCoder = new maps.Geocoder();
								geoCoder.geocode({'location': cords}, function(results, status) {
									if (status === 'OK') {
											setAddress(results[0].formatted_address);
									}
								});
							}}
						>
						<div className="marker" lat={parseFloat(cords.lat)} lng={parseFloat(cords.lng)} />
						</GoogleMapReact>
					</div>
						<h2 className="label">Dine koordinater er</h2>
						<h2 className="cords">{cords.lat}, {cords.lng}</h2>
						{address &&
							<div>
								<h2 className="label">Nærmeste adresse</h2>
								<h2 className="cords">{address}</h2>
							</div>
						}

						<a className="primary button" href="tel:80031660">Tilkall bilberging nå</a>
						<a className="primary button" href="tel:+4722303050">Tilkall bilberging nå ( utenlandsnummer )</a>
					</div>
				}
		</div>
	);
}
export default Locate;
