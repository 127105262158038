import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Header from 'components/Header';
import {
	apiRelay,
} from 'api';
import './style.scss';
import {
	Link
} from 'react-router-dom';

async function send(phone, setDone, setError) {
	setError(false);
	const response = await apiRelay( 'auth/new-password', {
		username: phone
	} );
	if(response.status === 'ok') {
		setDone(true);
	} else {
		switch (response.status) {
			case 'missing_username':
				setError('Fyll ut ditt telefonnummer');
				break;
			case 'invalid_username':
				setError('Telefonnummeret er ikke registert hos KNA');
				break;
			default:
				setError('Det blev feil, teste igjen senere');
		}
	}
}

function Password( props ) {
	const [phone, setPhone] = useState('');
	const [done, setDone] = useState(false);
	const [error, setError] = useState(false);
	return (
		<div>
			<Header navigation="menu">
				<div className="logo" />
			</Header>

			<div className="view login">
				{!done &&
					<div className="login-wrapper">
						<ul className="input-list">
							<li>
								<TextField
									label="Mobil"
									type="tel"
									variant="outlined"
									autoComplete="tel"
									value={phone}
									onChange={e => setPhone(e.target.value)}
								/>
							</li>
						</ul>
						{error &&
							<p><span className="error">{error}</span></p>
						}
						<button onClick={() => {send(phone, setDone, setError)}} type="button" className="block-link important" >Send meg nytt passord</button>
					</div>
				}
				{done &&
					<div><p style={{color: 'green', fontWeight: 600}}>Ditt passord har blitt sendt på SMS</p></div>
				}
				<Link to="/" className="block-link" >Tilbake til log-in</Link>
			</div>
		</div>
	);
}
export default Password;
