import React, {useState} from 'react';

import Menu from "./Menu";

function Header( props ) {
	const [ isMenuShown, setIsMenuShown ] = useState( false );

	const hideMenu = props.hideMenu || false;

	return (
		<>
			<div className="header">
				<a className="to-kna" href="https://kna.no/">Til KNA.no</a>

				{props.children}

				{ ! hideMenu &&
					<>
					{ ! isMenuShown &&
						<button className="toggle-menu" href="#" onClick={() => {setIsMenuShown(true)}}>
						<span className="screen-reader-text">
						Show menu
						</span>
						</button>
					}
					{ isMenuShown &&
						<button className="toggle-menu active" href="#" onClick={() => {setIsMenuShown( false )}}>
						<span className="screen-reader-text">
						Hide menu
						</span>
						</button>
					}
					</>
				}
			</div>

			{ isMenuShown && <Menu/> }
		</>
	);
}
export default Header;
