import React from 'react';

import './style.scss';

function DashCardGrid( props ) {

	return (
		<div className="dashcard-grid">
			{ props.children }
		</div>
	)

}

export default DashCardGrid;