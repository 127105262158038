import React from 'react';

import { Link } from 'react-router-dom';

import Header from 'components/Header';

// Helpers
import {
    logout
} from 'api';


function Logout( props ) {
    const {
        setUserId
    } = props;

    logout( setUserId );

    return (
        <div>
            <Header  navigation="menu">
                <div className="logo" />
            </Header>

            <div className="view">
                <h1>Logg ut</h1>

                <div className="input-list">
                    <p>
                        Du er nå utlogget fra KNA's medlemsapp.
                    </p>

                    <p>
                        Du vil måtte logge inn igjen for å få tilgang til medlemskort, og andre medlemsfordeler.
                    </p>

                    <Link to={"/"} className="block-link important plain">Til innloggingssiden</Link>
                </div>
            </div>
        </div>
    );
}

export default Logout;
