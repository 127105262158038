import React from 'react';

import './style.scss';
import {Link} from 'react-router-dom';

function ExpiredPayment( props ) {
	const { cardIsExpired, membershipIsPaused } = props;
	
	return (
		<div className="expired-payment-wrapper">
			<span className="icon bell"/>

			<br/>

			<strong>
				Heisann!
			</strong>

			<br/>
			<br/>
			{ cardIsExpired ?
				(
					'Betalingskortet ditt er utløpt' +
					( membershipIsPaused ? ' og medlemskapet ditt er pauset' : '' ) +
					'.'
				) : (
					'Vi ser at betalingskortet ditt snart utløper.'
				)
			}
			<br/>

			Vennligst oppdater betalingsinformasjonen din.

			<br/>
			<br/>

			<Link to="/betaling/kort" className="button primary inline">
				Oppdater betalingsmåte
			</Link>
		</div>
	)
}

export default ExpiredPayment;