import React from 'react';

import './style.scss';
import MenuItems from "./MenuItems";

function Menu( props ) {
	return (
		<div className="menu-wrapper">
			<MenuItems />
		</div>
	);
}

export default Menu;