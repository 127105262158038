import React from 'react';

import '../style.scss';

function GeneralToS( props ) {
    const {
        setValues,
        setModal,
        terms
    } = props;

    const confirmAndClose = () => {
        setValues( oldValues => ( {
            ...oldValues,
            acceptToS: true,
        } ) );

        setModal( 0 );
    };

    return (
        <>
            <div className="modal-inner">
                <button type="button" className="close-modal-button" onClick={ () => { setModal( 0 ); } }>X</button>

                <h2>Personvernerklæring & medlemsvilkår</h2>

                <div dangerouslySetInnerHTML={{__html:terms.consent}} />

                <button className="primary green" onClick={confirmAndClose}>Jeg godtar vilkårene</button>
            </div>
        </>
    )
}

export default GeneralToS;